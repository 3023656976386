import React from "react";
import styles from "../../styles/info-site/Stage.module.css";
import Cta from "../styleguide/Cta";
import TextInfo from "../styleguide/TextInfo";
// import StageLogo from "../../assets/stage-logo.png";
// import SimpleLogo from "../../assets/simple-logo.png"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/fontawesome-free-solid";
import { FormattedMessage } from "react-intl";

const Stage = ({
  title,
  noWrapTitle,
  description,
  featureList,
  primaryCtaLink,
  primaryCtaText, 
  secondaryCtaLink,
  secondaryCtaText,
  children,
  icon,
  withoutMedia,
  classTitle,
  trustItems
}) => {
    return (
        <div className={styles.Stage}>
            <div className={`${styles.StagePlaceholder} ${withoutMedia ? styles.StagePlaceholder__withoutMedia : ''}`}>
                {
                  withoutMedia
                    ? children
                    : (
                      <div className={styles.StageImage}>
                          {children}
                      </div>
                    )
                }
               <div className={styles.StageText}>
                    <TextInfo icon={icon} classTitle={classTitle} className={`${styles.StageTextInfo} ${icon ? styles.StageTextInfo__drop : ''} ${noWrapTitle ? styles.StageTextInfo__noWrap : ''}`} title={title} description={description} />
                    {
                      featureList?.length > 0 && (
                        <ul className={styles.FeatureList}>
                          {
                            featureList.map((item, key) => (
                              <li key={key}>
                                <FontAwesomeIcon style={{ width: '20px' }} size="xs" icon={faCheckCircle} />
                                <FormattedMessage id={item} />
                              </li>
                            ))
                          }
                        </ul>
                      )
                    }
                    <div className={styles.StageCtaWrapper}>
                      {
                        primaryCtaText && <Cta className={styles.StageCtaPrimary} link={primaryCtaLink} text={primaryCtaText} />
                      }
                      {!!secondaryCtaText && <Cta className={styles.StageCtaSecondary} link={secondaryCtaLink} text={secondaryCtaText} />}
                      {
                        trustItems?.length > 0 && (
                          <div className={styles.TrustItems}>
                            {
                              trustItems.map(item => (
                                <span key={item.replace(/s/g, '').toLocaleLowerCase()} className={styles.TrustItem}><FormattedMessage id={item} /></span>
                              ))
                            }
                          </div>
                        )
                      }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Stage;