import React from "react";
import { FormattedHTMLMessage } from "react-intl";
import SimpleLogo from "../../assets/simple-logo.png"
import styles from "./TextInfo.module.css";

const TextInfo = ({title, description, type, className, shadow, icon, classTitle }) => {
    const getTitle = () => {
        return <FormattedHTMLMessage id={title} />;
    };

    return (
        <div className={`${styles.TextInfo} ${shadow ? styles.TextInfo__shadow : ''} ${className}`}>
          
            {
              icon && <img className={styles.TextInfoSimpleLogo} src={SimpleLogo} alt='' />
            }
            {type === "primary" ? <h1 className={classTitle}>{getTitle()}</h1> : <h2 className={classTitle}>{getTitle()}</h2>}
            {
              description && <p><FormattedHTMLMessage id={description} /></p>
            }
        </div>
    );
};

export default TextInfo;