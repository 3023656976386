import React from "react";
import { FormattedMessage } from "react-intl";
import styles from "../../styles/info-site/CompaniesTestimonials.module.css";

const CompaniesTestimonials = ({ companies, title }) => {

    // const companiesItemsClx = useMemo(() => {
    //     if (companies.length === 2) {
    //       return styles.CompaniesTestimonialsListItem__half
    //     }
    //     if (companies.length % 3 === 0) {
    //       return styles.CompaniesTestimonialsListItem__third
    //     }
    //     if (companies.length % 4 === 0) {
    //       return styles.CompaniesTestimonialsListItem__forth
    //     }
    //     if (companies.length % 5 === 0) {
    //       return styles.CompaniesTestimonialsListItem__fifth
    //     }
    //     return ''
    // }, [companies.length])

    return (
        <div className={styles.CompaniesTestimonials}>
          {
            title && <h2 className={styles.CompaniesTestimonialsTitle}><FormattedMessage id={title} /></h2>
          }
            
            {
              companies && (
                <ul className={styles.CompaniesTestimonialsList}>
                  {
                    companies.map(({ img, alt, id}) => (
                      <li className={styles.CompaniesTestimonialsListItem} key={id}>
                        <img src={img} alt={alt} />
                      </li>
                    ))
                  }
                </ul>
              )
            }
        </div>
    );
};

export default CompaniesTestimonials;